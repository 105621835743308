import { AudioContext } from 'standardized-audio-context';

// Importing the mp3 files as modules so Parcel is aware of them
import frontLeftChannel from 'url:./assets/Front_Left_Channel.mp3';
import frontRightChannel from 'url:./assets/Front_Right_Channel.mp3';
import centerChannel from 'url:./assets/Center_Channel.mp3';
import subwoofer from 'url:./assets/Subwoofer.mp3';
import surroundLeftChannel from 'url:./assets/Surround_Left_Channel.mp3';
import surroundRightChannel from 'url:./assets/Surround_Right_Channel.mp3';

// Placeholder imports for the samples
import surroundTestSample from 'url:./assets/samples/5.1_Surround_Test.wav';


/*
import forestSample from 'url:./assets/samples/Forest.mp3';
import citySample from 'url:./assets/samples/City.mp3';
import jetFlybySample from 'url:./assets/samples/Jet_flyby.mp3';
import helicopterSample from 'url:./assets/samples/Helicopter.mp3';
import stringQuartetSample from 'url:./assets/samples/String_quartet.mp3';
import jazzSample from 'url:./assets/samples/Jazz.mp3';
*/
import forestSample from 'url:./assets/samples/5.1_Surround_Test.wav';
import citySample from 'url:./assets/samples/5.1_Surround_Test.wav';
import jetFlybySample from 'url:./assets/samples/5.1_Surround_Test.wav';
import helicopterSample from 'url:./assets/samples/5.1_Surround_Test.wav';
import stringQuartetSample from 'url:./assets/samples/5.1_Surround_Test.wav';
import jazzSample from 'url:./assets/samples/5.1_Surround_Test.wav';


const channelFiles = [
  frontLeftChannel,
  frontRightChannel,
  centerChannel,
  subwoofer,
  surroundLeftChannel,
  surroundRightChannel
];

const sampleFiles = {
  '5.1 Surround Test': surroundTestSample,
  'Forest': forestSample,
  'City': citySample,
  'Jet flyby': jetFlybySample,
  'Helicopter': helicopterSample,
  'String quartet': stringQuartetSample,
  'Jazz': jazzSample
};

let audioContext;
let channelMerger;

// Function to initialize the audio context and setup the environment
export const initializeAudioContext = async () => {
  if (!window.AudioContext && !AudioContext) {
    console.error('Web Audio API is not supported in your browser.');
    return;
  }

  audioContext = new (window.AudioContext || AudioContext)();

  // Check if the destination supports 6 channels and log the channel count
  if (audioContext.destination.maxChannelCount >= 6) {
    audioContext.destination.channelCount = 6;
    console.log(`Maximum channel count supported: ${audioContext.destination.maxChannelCount}`);
    console.log('Setting channel count to 6 for 5.1 surround sound.');
  } else {
    audioContext.destination.channelCount = 2;
    console.log('Falling back to stereo sound with 2 channels.');
  }

  channelMerger = audioContext.createChannelMerger(6);
  channelMerger.connect(audioContext.destination);
};

// Function to load and play a file through a specific channel
const playFileThroughChannel = async (filePath, channel) => {
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(channelMerger, 0, channel);
  source.start();
};

// Expose function to play a specific channel
export const playChannel = async (channel) => {
  if (channel < 0 || channel >= channelFiles.length) {
    console.error('Invalid channel number.');
    return;
  }
  await playFileThroughChannel(channelFiles[channel], channel);
};

// Function to play a sample through the multichannel output using a new method
export const playSample = async (sampleName) => {
  if (!sampleFiles[sampleName]) {
    console.error('Sample not found.');
    return;
  }
  const filePath = sampleFiles[sampleName];
  await playMultichannelAudioFile(filePath);
};

// New function to play a multichannel audio file directly without using a channel merger
const playMultichannelAudioFile = async (filePath) => {
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const source = audioContext.createBufferSource();
  source.buffer = audioBuffer;
  source.connect(audioContext.destination);
  source.start();
};
